<script>
import * as Status from '../../../../../domain/leiloes/helpers/LoteStatus'
export default{
  name: 'Stats',
  inject: ['controlador'],
  props: {
    lote: {required: false},
    leilao: {required: false},
    hideStatus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hasNovoLance: false
    }
  },
  computed: {
    colorStatus () { // TODO
      if (this.lote.status === null) {
        return '#FFFFFF'
      }
      if (typeof Status.StatusFake[this.lote.status]['color'] !== 'undefined') {
        return Status.StatusFake[this.lote.status]['color']
      }
      return '#FFFFFF'
    },
    minimoStyle () {
      return {
        'pregao-valor-minimo-superado': this.controlador.valorAtual >= Number(this.lote.valorMinimo)
      }
    }
  },
  created () {
  },
  destroyed () {
  },
  methods: {
  },
  components: {}
}
</script>

<template>
  <div class="lote-leilao-stats">
    <div class="tab-lance-numlote">
      <div class="stats-title">Lote</div>
      <div class="stats-input">{{ controlador.loteNumero }}</div>
    </div>
    <div v-if="leilao.cronometro !== false" class="tab-lance-timer">
      <div class="stats-title">Tempo</div>
      <div class="stats-input lance-timer" :class="{'lance-danger': controlador.timerPregao <= controlador.calcPercentTimer(30)}">
        {{ controlador.timerPregaoFormatado }}
      </div>
    </div>
    <div class="tab-valor-atual" :class="minimoStyle">
      <div class="stats-title">Valor atual</div>
      <div v-if="controlador.ultimoLance" class="stats-input valor-atual" :class="{novoLance: hasNovoLance}">R$ {{ controlador.valorAtual | moeda }}</div>
      <div v-else class="stats-input valor-atual">-</div>
    </div>
    <div class="tab-ultimo-lance">
      <div class="stats-title">Último lance</div>
      <div class="stats-input ultimo-lance">
        <div v-if="controlador.ultimoLance">
          {{ controlador.ultimoLance.autor.apelido }}
          <span>{{ controlador.lanceLocalidade }}</span>
        </div>
        <div v-else>
          -
        </div>
      </div>
    </div>
    <div class="tab-status" v-if="!hideStatus">
      <div class="stats-title">Status</div>
      <div class="stats-input lote-status s-pregao"><span :style="'color: ' + colorStatus">{{ controlador.loteStatusString }}</span>
      </div>
    </div>
  </div>
</template>
