<template>
  <li>
    <div class="label">{{label}}</div>
    <div class="right">
      <div class="count">{{count}}</div>
      <div class="value" :class="color">{{value}}</div>
    </div>
  </li>
</template>
<script>
export default {
  name: 'stats-tab-item',
  props: ['label', 'count', 'value', 'color']
}
</script>
