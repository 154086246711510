<template>
  <div class="screen-options">
    <a @click="$emit('toggleKeyboard')" :class="{'active': activeKeyboard}"><u-icon type="fa" icon-style="light" name="keyboard" /></a>
    <a @click="$emit('toggleKeyboardStats')" :class="{'active': activeResume}"><u-icon type="fa" icon-style="light" name="user-chart" /></a>
  </div>
</template>

<script>
export default {
  name: 'ScreenOptions',
  props: {
    activeKeyboard: {
      type: Boolean,
      default: false
    },
    activeResume: {
      type: Boolean,
      default: false
    }
  }
}
</script>
