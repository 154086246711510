<script>
  import {UProgress} from 'uloc-vue'
  export default {
    name: 'change-status',
    data () {
      return {
        opened: false,
        message: null,
        progressp: 0
      }
    },
    computed: {
      getMessage () {
        return this.message
      }
    },
    mounted () {
    },
    watch: {},
    destroyed () {
    },
    methods: {
      open (message) {
        this.opened = true
        this.message = message
        this.$nextTick(() => {
          this.progressp = 0
        })
      },
      close () {
        this.progressp = 100
        window.setTimeout(() => {
          this.opened = false
          this.progressp = 0
        }, 500)
      }
    },
    components: {UProgress}
  }
</script>

<template>
  <div>
    <div class="u-theme-config-container" :class="{'is-closed': opened}">

      <div class="u-theme-config-panel" :class="{'open': opened}">

        <div class="u-theme-content">

          <div class="center-progress">
            <u-progress :percentage="progressp" ref="progressbar" />
            <!--<progress-bar pclass="progress2" height="6px" color="#23b7e5" ref="progressbar"></progress-bar>-->
          </div>

          <div class="message">{{ message }}</div>

        </div>
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
  .u-bg-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
    z-index: 9999996;
  }

  .u-theme-config-container {
    position: fixed;
    z-index: 9999997;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 30px;
    display: flex;
    align-content: center;
    align-items: center;
    &.is-closed {
      animation-name: fecharBonitin;
      animation-duration: .2s;
      -webkit-animation-fill-mode: forwards;
    }
  }

  .u-theme-config i:before {
    font-size: 50px;
  }

  .u-theme-config-panel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: fixed;
    z-index: 9999998;
    bottom: -300px;
    right: 20px;
    width: 330px;
    max-height: calc(100vh - 200px);
    border-radius: 5px;
    box-shadow: 0 2px 24px 0 rgba(97, 123, 160, 0.8);
    opacity: 0;
    transition: all 0.3s;
    margin: auto;
    background: #dddddd; /* Old browsers */
    background: -moz-linear-gradient(top, #dddddd 0%, #ffffff 84%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #dddddd 0%,#ffffff 84%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #dddddd 0%,#ffffff 84%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dddddd', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    &.open {
      bottom: -20px !important;
      opacity: 1;
      transition: all 0.3s;
    }
  }

  .u-theme-content {
    padding: 22px;
  }

  .message{
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .center-progress{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .progress2{
    /*border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;*/
  }

</style>
