<script>
import {UPopover} from 'uloc-vue'
import MenuOptionsItem from '../../../../layout/context-menu/context-window-options-item'

export default {
  name: 'BtnMenuSup',
  props: ['leilao'],
  inject: ['controlador'],
  components: {MenuOptionsItem, UPopover},
  computed: {
  },
  methods: {
    irLote () {
      console.log('Digite o número do lote: ')
      this.controlador.$refs.keyboard.selecionarLote()
    },
    mensagemPublica () {
      this.controlador.$refs.keyboard.mensagemPublica()
    },
    sair () {
      this.$router.push({name: 'leiloes.show', params: {id: this.leilao.id}})
    }
  }
}
</script>

<template>
  <e-btn align="left" label="Opções">
    <span class="m-l-md"></span> <u-icon name="chevron-down" type="fa" class="absolute-right m-l m-r-sm text-grey-7" style="font-size: 10px !important;"/>
    <u-popover class="window-context-menu no-border-radius" anchor="bottom left" self="top left">
      <div class="menu_ menu-btn-leilao">
        <ul>
          <menu-options-item @click.native="irLote" icon="forward" icon-type="fa" label="Ir para um lote" shortcut="F6" close  />
<!--          <menu-options-item label="Pausar Leilão" icon="pause" icon-type="fa" close  />-->
          <menu-options-item @click.native="mensagemPublica" label="Enviar Mensagem Pública" icon="envelope" icon-type="fa" close  />
          <menu-options-item @click="$emit('fecharLeilao')" v-if="leilao.pregaoAtivo" label="Fechar Auditório" icon="stop" icon-type="fa" close  />
          <hr/>
          <menu-options-item label="Sair" @click="sair" close  />
        </ul>
        <slot />
      </div>
    </u-popover>
  </e-btn>
</template>
