<script>
export default {
  name: 'LoteInfo',
  inject: ['controlador'],
  props: ['lote', 'leilao'],
  data () {
    return {}
  },
  computed: {
    hasLancesAutomaticos() {
      return this.lote.lancesAutomaticos && Array.isArray(this.lote.lancesAutomaticos) && this.lote.lancesAutomaticos.slice().filter(l => l.active).length
    },
    maiorLanceAutomativo () {
      const result = this.lote.lancesAutomaticos.slice().filter(l => l.active).sort((a, b) => {
        return (a.valorLimite === b.valorLimite ? 0 : a.valorLimite > b.valorLimite ? -1 : 1) * 1
      })
      return result[0].valorLimite
    }
  },
  mounted () {
  },
  destroyed () {
  },
  methods: {},
  components: {}
}
</script>

<template>
  <div class="lote-info" v-if="lote && lote.bem">
    <div class="lote-img" v-if="lote.bem.image" :class="['lote-img-status' + lote.status]"
         :style="{backgroundImage: 'url('+lote.bem.image.min.url || lote.bem.image.url+')'}"></div>
    <div class="lote-img" v-else>
      <div class="noimg h-full w-full flex flex-center items-center justify-center column text-grey-6 no-select">
        <div>
          <u-icon name="image" />
        </div>
        Sem imagem
      </div>
    </div>
    <div class="lote-detalhes">
      <div class="legendas">
        <div class="comitente">{{ lote.bem.comitente && lote.bem.comitente.pessoa.name }}</div>
        <div class="titulo">
          {{ String(lote.bem.siteTitulo).length > 130 ? String(lote.bem.siteTitulo).substring(0, 130)+'...' : String(lote.bem.siteTitulo) }}
        </div>
        <div class="descricao" :title="lote.bem.siteSubtitulo">
          {{ lote.bem.siteSubtitulo ? (String(lote.bem.siteSubtitulo).length > 130 ? String(lote.bem.siteSubtitulo).substring(0, 130)+'...' :
          String(lote.bem.siteSubtitulo)) : null }}
        </div>
      </div>
      <div class="valores">
        <div class="row">
          <div @click="$emit('alteraLanceInicial')">
            <div class="lbl">Inicial<span>F10</span></div>
            <div class="val" v-if="leilao.praca === 1">R$ {{ lote.valorInicial | moeda }}</div>
            <div class="val" v-if="leilao.praca === 2">R$ {{ lote.valorInicial2 | moeda }}</div>
            <div class="val" v-if="leilao.praca === 3">R$ {{ lote.valorInicial3 | moeda }}</div>
          </div>

          <div @click="$emit('alteraValorMinimo')">
            <div class="lbl">Mínimo<span>Ctrl+F10</span></div>
            <div class="val">R$ {{ lote.valorMinimo | moeda }}</div>
          </div>

          <div @click="$emit('alteraIncremento')">
            <div class="lbl">Incremento<span>F3</span></div>
            <div class="val">R$ {{ lote.valorIncremento | moeda }}</div>
          </div>
        </div>

        <div class="row">
          <div>
            <div class="lbl">AVALIAÇÃO</div>
            <div class="val">R$ {{ lote.valorAvaliacao | moeda }}</div>
          </div>
          <div>
            <div class="lbl">Total de lances</div>
            <div class="val">{{ lote.lances.length }}</div>
          </div>

<!--          <div>
            <div class="lbl">Participantes</div>
            <div class="val">{{ controlador.estatisticas ? controlador.estatisticas.pessoasOnline : '...' }}</div>
          </div>-->

          <div class="lances-automaticos"> <!-- @TODO: V2.1 - Poder clicar e mostrar os lances automáticos -->
            <div class="lbl">Lance Automático</div>
            <div class="val la" v-if="hasLancesAutomaticos">ATÉ <strong>R$ {{ maiorLanceAutomativo|moeda }}</strong></div>
            <div class="val" v-else>Não</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    Abra o auditório para carregar o primeiro lote.
  </div>
</template>
