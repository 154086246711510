<script>
import ChangeStatus from '../include/ChangeStatus.vue'
// import Service from '../../../../domain/leiloes/services/pregao'
// import {number_format as numberFormat} from '../../../../utils/helpers/phpjs'
import * as Status from '../../../../../domain/leiloes/helpers/LoteStatus'
import {updateGlobalconfig} from '../../../../../domain/globalconfig/services';
import UConfirm from '../include/Confirm'
import AuxiliarLance from './AuxiliarLance'
import {UCheckbox} from 'uloc-vue'
import LanceManual from './LanceManual'
import MudarIncremento from './MudarIncremento'
import LanceInicial from './LanceInicial'
import LanceMinimo from './LanceMinimo'
import AlteraCronometro from './AlteraCronometro'

export default {
  name: 'keyboard',
  inject: ['controlador'],
  props: {
    'leilao': {required: true},
    'lote': {required: true},
    state: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      show: this.state,
      dblkeyLance: 0,
      processandoLance: false,
      processandoDelete: false,
      autoConfirm: false
    }
  },
  created () {
    /* document.addEventListener('keyup', (pKey) => {
     console.log(pKey)
     }) */
  },
  computed: {
    Status () {
      return Status
    }
  },
  mounted () {
    this.$confirm = this.$refs.confirm
  },
  watch: {
    state (v) {
      this.show = v
    }
  },
  destroyed () {
  },
  methods: {
    toggleKeyboard () {
      console.log('Toggle me')
      this.show = !this.show
      this.$emit('toggle', this.show)
    },
    selecionarLote () {
      console.log('Selecionar Lote')
      /* let numero = prompt('Qual o número do lote?')
      if (parseInt(numero) < 0 || typeof numero === 'undefined' || numero === null) {
        return
      } */
      this.$uloc.dialog({
        title: 'Qual o número do lote?',
        prompt: {
          model: '',
          type: 'number' // optional
        },
        cancel: true,
        color: 'secondary'
      }).then(numero => {
        console.log(numero, parseInt(numero))
        if (Number.isNaN(parseInt(numero)) || parseInt(numero) <= 0 || typeof numero === 'undefined' || numero === null) {
          return
        }
        this.mudarLoteEspecifico(numero)
      }).catch(() => {
      })
    },
    mensagemPublica () {
      console.log('Digitar mensagem pública')
      this.$uloc.dialog({
        title: 'Qual a mensagem? Lembrando que a mensagem ficará disponível por 15 segundos',
        prompt: {
          model: '',
          type: 'text' // optional
        },
        cancel: true,
        color: 'secondary'
      }).then(mensagem => {
        console.log(mensagem)
        if (typeof mensagem === 'undefined' || mensagem === null) {
          return
        }
        this.$refs.changeStatus.open('Enviando mensagem pública')
        this.comunicatorClass.mensagemAuditorio(this.leilao.id, mensagem)
            .then((response) => {
              this.$refs.changeStatus.close()
              this.$uloc.dialog({
                title: 'Mensagem enviada!',
                color: 'positive',
                message: 'A mensagem foi enviada com sucesso para o público do auditório.'
              })
              // this.$emit('mudarLote', {acao: 'avancar', lote: response.data.lote, pregao: response.data.pregao})
            })
            .catch(({response}) => {
              this.$refs.changeStatus.close()
              console.log(response)
              // window.alert('Erro ao mudar de lote. Tente novamente, caso o erro persista, contate o administrador do sistema.')
              this.alertApiError(response)
            })
      }).catch(() => {
      })
    },
    mudarLoteEspecifico (numero) {
      console.log('Trocando lote para o anterior...')
      this.$refs.changeStatus.open('Mudando para Lote ' + numero)
      this._mudaLote('ir', numero)
    },
    anterior () {
      console.log('Trocando lote para o anterior...')
      this.$refs.changeStatus.open('Mudando para Lote Anterior')
      this._mudaLote('voltar')
    },

    proximo () {
      console.log('Trocando lote para o próximo...')
      this.$refs.changeStatus.open('Mudando para Próximo Lote')
      this._mudaLote('passar')
    },
    _mudaLote (comando, numero = null) {
      this.comunicatorClass.mudarLote(this.leilao.id, comando, numero)
        .then((response) => {
          this.$refs.changeStatus.close()
          // this.$emit('mudarLote', {acao: 'avancar', lote: response.data.lote, pregao: response.data.pregao})
        })
        .catch(({response}) => {
          this.$refs.changeStatus.close()
          if (Number(response.status) === 401) {
            window.alert('Não há mais lotes para passar')
            return
          }
          console.log(response)
          // window.alert('Erro ao mudar de lote. Tente novamente, caso o erro persista, contate o administrador do sistema.')
          this.alertApiError(response)
        })
    },

    deletarLance () {
      window.setTimeout(() => {
        this.processandoDelete = false
      }, 3000)

      if (this.processandoDelete) {
        return
      }

      this.processandoDelete = true
      console.log('Deletando último lance...')
      if (!this.controlador.ultimoLance) {
        window.alert('Nenhum lance para apagar')
        // this.processandoDelete = false
        return
      }
      let lance = this.controlador.ultimoLance
      this.$confirm.open('Tem certeza que deseja deletar o lance de ' + lance.arrematante.apelido + ' no valor de R$ ' + this.$options.filters.moeda(lance.valor, 2, ',', '.') + '?', {
        message: 'Deletar',
        color: 'btn-danger'
      }, 'Cancelar', null, (v) => {
        if (v === false) {
          this.processandoDelete = false
          return
        }
        console.log('Deletando lance ' + lance.id + '...')
        this.$refs.changeStatus.open('Deletando Lance')

        this.comunicatorClass.deleteLance(lance.id)
          .then((response) => {
            this.processandoDelete = false
            this.$refs.changeStatus.close()
            this.$emit('deletarLance', lance)
          })
          .catch(({response}) => {
            this.processandoDelete = false
            this.$refs.changeStatus.close()
            console.log(response)
            this.alertApiError(response)
          })
      })
    },

    doulhe (n, lote) {
      let s = 'Dou-lhe Uma'
      if (n === 2){
        s = 'Dou-lhe uas'
      }
      if (typeof lote === 'undefined') {
        lote = this.controlador.lote.id
      }
      console.log('Trocando status do lote para ' + s + '...')
      this.$refs.changeStatus.open('Informando ' + s)
      this.comunicatorClass.doulhe(lote, n)
          .then((response) => {
            this.$refs.changeStatus.close()
          })
          .catch(({response}) => {
            this.$refs.changeStatus.close()
            console.log(response)
            this.alertApiError(response)
          })
    },

    loteStatus (s, lote, noConfirm) {
      noConfirm = typeof noConfirm !== 'undefined' ? noConfirm : false
      if (typeof lote === 'undefined') {
        lote = this.controlador.lote.id
      }
      this.$confirm.open('Tem certeza que deseja alterar o status do lote para ' + Status.StatusTitle(s) + '?', 'Alterar Status para ' + Status.StatusTitle(s), 'Cancelar', null, (v) => {
        if (v === false) {
          return
        }
        this.updateLoteStatus(s, lote)
      })
    },
    updateLoteStatus (s, lote) {
      console.log('Trocando status do lote para ' + s + '...')
      this.$refs.changeStatus.open('Alterando status do lote')
      this.comunicatorClass.alterarStatusLote(lote, s)
        .then((response) => {
          this.$refs.changeStatus.close()
          this.$emit('statusLote', response.data.lote)
        })
        .catch(({response}) => {
          this.$refs.changeStatus.close()
          console.log(response)
          this.alertApiError(response)
        })
    },
    lance (valor = null) {
      this.$refs.changeStatus.open('Efetuando lance')
      if (!valor) {
        console.log(this.controlador.ultimoLance)
        if (this.controlador.ultimoLance) {
          if (this.controlador.ultimoLance.arrematante.apelido !== 'PLATEIA') {
            this.$refs.changeStatus.open('Último lance com origem online, pressione duas vezes rapidamente a tecla + para cobrir o lance')
            console.log('Último lance com origem online, pressione rapidamente duas vezes a tecla + para cobrir o lance.')
            this.dblkeyLance++
            if (this.dblkeyLance === 1) {
              let self = this
              this.timerKeyLance = setTimeout(function () {
                self.dblkeyLance = 0
              }, 500)
              return
            } else {
              clearTimeout(this.timerKeyLance)
              this.$refs.changeStatus.open('Efetuando lance')
              this.dblkeyLance = 0
            }
          }
        }
      }
      if (this.processandoLance) return

      if (!valor || Number.isNaN(Number(valor))) {
        valor = this.controlador.ultimoLance ? (this.controlador.ultimoLance.valor + this.lote.valorIncremento) : (this.lote.valorInicial || this.lote.valorIncremento)
      }
      this.processandoLance = true
      this.comunicatorClass.lance(this.lote.id, valor)
        .then((response) => {
          this.$refs.changeStatus.close()
          this.focusMe()
          this.processandoLance = false
          // this.$emit('novoLance', response.data.lote, response.data.lance)
        })
        .catch(({response}) => {
          this.processandoLance = false
          this.$refs.changeStatus.close()
          console.log(response)
          this.alertApiError(response)
        })
    },
    doulheUma () {
      console.log('Alterando status do lote para Dou-lhe Uma...')
    },
    doulheDuas () {
      console.log('Alterando status do lote para Dou-lhe Duas...')
    },
    baixaOferta () {
      console.log('Alterando status do lote para Baixa Oferta...')
    },
    semLicitantes () {
      console.log('Alterando status do lote para Sem Licitantes...')
    },
    homologando () {
      console.log('Homologando...')
    },
    condicional () {
      console.log('Alterando status do lote para Condicional...')
    },
    vendido () {
      console.log('Alterando status do lote para Vendido...')
    },
    renovarTempo () {
      console.log('Renovando tempo do pregão/lote')
      this.$refs.changeStatus.open('Renovando cronômetro.')
      this.comunicatorClass.renovarCronometro(this.lote.id)
        .then((response) => {
          this.$refs.changeStatus.close()
        })
        .catch(({response}) => {
          this.$refs.changeStatus.close()
          console.log(response)
          this.alertApiError(response)
        })
    },
    alterarIncremento (valor) {
      if (this.valor === 0) {
        return
      }
      this.controlador.$refs.keyboard.$refs.changeStatus.open('Mudando incremento')
      this.comunicatorClass.alterarIncrementoLote(this.controlador.lote.id, valor)
        .then(response => {
          console.log(response.data)
          this.controlador.$refs.keyboard.$refs.changeStatus.close()
        })
        .catch(({response}) => {
          console.log(response)
          this.controlador.$refs.keyboard.$refs.changeStatus.close()
          this.alertApiError(response)
        })
    },
    alteraLanceInicial () {
      this.$refs.lanceInicial && this.$refs.lanceInicial.digitarLance()
    },
    alteraValorMinimo () {
      this.$refs.lanceInicial && this.$refs.lanceMinimo.digitarLance()
    },
    alteraIncremento () {
      this.$refs.lanceIncremento && this.$refs.lanceIncremento.digitarIncremento()
    },
    alterarLanceInicial (v) {
      this.$refs.changeStatus.open('Mudando valor inicial')
      this.comunicatorClass.alterarValorInicialLote(this.controlador.lote.id, v)
        .then(response => {
          console.log(response.data)
          this.controlador.$refs.keyboard.$refs.changeStatus.close()
        })
        .catch(({response}) => {
          console.log(response)
          this.controlador.$refs.keyboard.$refs.changeStatus.close()
          this.alertApiError(response)
        })
    },
    alterarLanceMinimo (v) {
      this.$refs.changeStatus.open('Mudando valor mínimo')
      this.comunicatorClass.alterarValorMinimoLote(this.controlador.lote.id, v)
        .then(response => {
          console.log(response.data)
          this.controlador.$refs.keyboard.$refs.changeStatus.close()
        })
        .catch(({response}) => {
          console.log(response)
          this.controlador.$refs.keyboard.$refs.changeStatus.close()
          this.alertApiError(response)
        })
    },
    alterarCronometro (tudo, tempo) {
      this.$refs.changeStatus.open('Mudando tempo do cronômetro')
      console.log(tudo, tempo)
      let endpoint, id
      if (tudo) {
        id = this.controlador.leilao.id
        endpoint = () => this.comunicatorClass.alterarCronometroLeilao(id, tempo)
      } else {
        id = this.controlador.lote.id
        endpoint = () => this.comunicatorClass.alterarCronometroLote(id, tempo)
      }
      endpoint().then(response => {
        console.log(response)
        this.controlador.$refs.keyboard.$refs.changeStatus.close()
      })
        .catch((error) => {
          console.log(error)
          this.controlador.$refs.keyboard.$refs.changeStatus.close()
          this.alertApiError(error)
        })
    },
    deletarLancesLote () {
      this.$uloc.dialog({
        title: 'Tem certeza que deseja DELETAR todos os lances deste lote? Esta operação não poderá ser revertida.',
        message: 'Digite SIM e clique para confirmar:',
        prompt: {
          model: '',
          type: 'text' // optional
        },
        cancel: true,
        color: 'secondary'
      }).then(data => {
        if (data.toUpperCase() !== 'SIM') {
          this.$uloc.notify({
            color: 'negative',
            message: `Você não confirmou a operação. Atualização não efetuada.`
          })
          return
        }
        this.$uloc.notify({
          color: 'negative',
          message: `Removendo todos os lances do lote...`
        })
        this.comunicatorClass.deleteLancesLote(this.lote.id).then(response => {
          console.log(response)
          this.controlador.$refs.keyboard.$refs.changeStatus.close()
        })
          .catch((error) => {
            console.log(error)
            this.controlador.$refs.keyboard.$refs.changeStatus.close()
            this.alertApiError(error)
          })
      }).catch(() => {
      })
    },
    focusMe () {
      this.$refs.focusme && this.$refs.focusme.focus()
    }
  },
  components: {
    AlteraCronometro,
    LanceMinimo,
    LanceInicial,
    MudarIncremento,
    LanceManual,
    AuxiliarLance,
    ChangeStatus,
    UConfirm,
    UCheckbox
  }
}
</script>

<template>
  <div>
    <u-confirm ref="confirm" :auto-confirm="autoConfirm" />
    <a ref="focusme" style="width: 0; height: 0"></a>
    <a v-shortkey="['f6']" @shortkey="selecionarLote"></a>
    <change-status ref="changeStatus"></change-status>
    <div class="keyboard" :class="{khide: !show}">
      <!--<a class="toggle" @click="toggleKeyboard"></a>-->

      <div class="autoConfirm">
        <u-checkbox color="positive" v-model="autoConfirm" label="Auto confirmar ações" />
      </div>

      <div class="buttons">
        <a class="control-anterior" v-shortkey="['arrowleft']" @shortkey="anterior"
           @click="anterior">Anterior <i>&larr;</i></a>
        <a class="control-proximo" v-shortkey="['arrowright']" @shortkey="proximo" @click="proximo">Próximo <i>
          &rarr;</i></a>
        <a class="control-deletar-lance" v-shortkey="['delete']"
           @shortkey="deletarLance" @click="deletarLance">Deletar Lance <i>Delete</i></a>
        <a class="control-aberto" v-shortkey="['1']" @shortkey="loteStatus(Status.STATUS_ABERTO_PARA_LANCES)"
           @click="loteStatus(Status.STATUS_ABERTO_PARA_LANCES)">Aberto <i>1</i></a>
        <a class="control-pregao" v-shortkey="['2']" @shortkey="loteStatus(Status.STATUS_EM_PREGAO)"
           @click="loteStatus(Status.STATUS_EM_PREGAO)">Em Pregão <i>2</i></a>
        <a class="control-incremento" v-shortkey="['+']" @shortkey="lance(null)"
           @click="lance(null)">Incremento <i>+</i></a>
        <a class="control-dolheuma" v-shortkey="['3']" @shortkey="doulhe(1)"
           @click="doulhe(1)">Dou-lhe Uma <i>3</i></a>
        <a class="control-doulheduas" v-shortkey="['4']" @shortkey="doulhe(2)"
           @click="doulhe(2)">Dou-lhe Duas <i>4</i></a>
        <a class="control-baixaoferta" v-shortkey="['0']" @shortkey="loteStatus(Status.STATUS_BAIXA_OFERTA)"
           @click="loteStatus(Status.STATUS_BAIXA_OFERTA)">Rejeitado <i>0</i></a>
        <a class="control-semlicitantes" v-shortkey="[',']" @shortkey="loteStatus(Status.STATUS_SEM_LICITANTES)"
           @click="loteStatus(Status.STATUS_SEM_LICITANTES)">Sem Licitantes <i>,</i></a>
        <a class="control-homologando" v-shortkey="['*']" @shortkey="loteStatus(Status.STATUS_HOMOLOGANDO)"
           @click="loteStatus(Status.STATUS_HOMOLOGANDO)">Homologando <i>*</i></a>
        <a class="control-condicional" v-shortkey="['-']" @shortkey="loteStatus(Status.STATUS_REPASSE)"
           @click="loteStatus(Status.STATUS_REPASSE)">Repasse <i>-</i></a>
        <a class="control-condicional" v-shortkey="['9']" @shortkey="loteStatus(Status.STATUS_CONDICIONAL)"
           @click="loteStatus(Status.STATUS_CONDICIONAL)">Condicional <i>9</i></a>
        <a class="control-vendido" v-shortkey="['.']" @shortkey="loteStatus(Status.STATUS_VENDIDO)"
           @click="loteStatus(Status.STATUS_VENDIDO)">Vendido <i>.</i></a>
      </div>
      <div class="renovar-tempo text-center no-select hide" v-shortkey="['t']" @shortkey="renovarTempo()">
        Aperte <strong class="label label-default box-shadow m-r-xs m-l-xs">T</strong> para renovar o tempo
      </div>

      <auxiliar-lance :lote="lote" :leilao="leilao" @lance="(v) => lance(v)" />
      <lance-manual @lance="(v) => lance(v)" />
      <mudar-incremento ref="lanceIncremento" @alterarIncremento="(v) => alterarIncremento(v)" :lote="lote"
                        :leilao="leilao" />

      <!-- HIDDEN ITEMS -->
      <lance-inicial @lanceInicial="(v) => alterarLanceInicial(v)" ref="lanceInicial" />
      <lance-minimo @lanceMinimo="(v) => alterarLanceMinimo(v)" ref="lanceMinimo" />
      <altera-cronometro @alterarCronometro="({tudo, tempo}) => alterarCronometro(tudo, tempo)"
                         ref="alteraCronometro" />
    </div>
  </div>
</template>
