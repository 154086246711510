<script>
import Keyboard from './Keyboard'
import StatsTabItem from './StatsTabItem'
import {estatisticas} from '@/domain/leiloes/services'
import ChangeStatus from '../include/ChangeStatus.vue'

export default {
  name: 'Estatisticas',
  mixins: [Keyboard],
  data () {
    return {
      e: {
        condicional: 0,
        condicionalValorTotal: 0,
        lotes: 0,
        naoVendidos: 0,
        paraLeiloar: 0,
        retirados: 0,
        vendidos: 0,
        vendidosValorTotal: 0
      }
    }
  },
  created () {
  },
  computed: {
    Status () {
      return Status
    },
    totalVendidoValor () {
      let total = this.e.vendidosValorTotal
      return `R$ ${this.$options.filters.moeda(total)}`
    },
    totalVCondicionalValor () {
      let total = this.e.condicionalValorTotal
      return `R$ ${this.$options.filters.moeda(total)}`
    },
    pctVendidos () {
      const total = this.e.lotes
      const vendidos = this.e.vendidos
      let result = ((vendidos/total) * 100).toFixed(2)
      if (!Number.isNaN(Number(result))) {
        return this.formatNumber(result) + '%'
      }
      return '-'
    },
    pctNaoVendidos () {
      const total = this.e.lotes
      const nvendidos = this.e.naoVendidos
      let result = ((nvendidos/total) * 100).toFixed(2)
      if (!Number.isNaN(Number(result))) {
        return this.formatNumber(result) + '%'
      }
      return '-'
    },
    pctCondicional () {
      const total = this.e.lotes
      const condicional = this.e.condicional
      let result = ((condicional/total) * 100).toFixed(2)
      if (!Number.isNaN(Number(result))) {
        return this.formatNumber(result) + '%'
      }
      return '-'
    },
    pctParaLeiloar () {
      const total = this.e.lotes
      const paraLeiloar = this.e.paraLeiloar
      let result = ((paraLeiloar/total) * 100).toFixed(2)
      if (!Number.isNaN(Number(result))) {
        return this.formatNumber(result) + '%'
      }
      return '-'
    }
  },
  mounted () {
    this.loadEstatisticas()
  },
  watch: {
    lote (v) {
      this.loadEstatisticas()
    }
  },
  destroyed () {
  },
  methods: {
    loadEstatisticas () {
      estatisticas(this.leilao.id)
        .then(({data}) => {
          console.log(data)
          this.e = data
          this.$emit('estatisticas', data)
        })
        .catch(error => {
          console.error('Não foi possível carregar as estatísticas do leilão.')
        })
    },
    formatNumber (n) {
      if (String(n).endsWith('.00')){
        return parseInt(Number(n))
      }
      return n
    }
  },
  components: {
    StatsTabItem,
    ChangeStatus,
  }
}
</script>

<template>
  <div>
    <change-status ref="changeStatus"></change-status>
    <div class="keyboard-stats keyboard" :class="{khide: !show}">

      <div class="stats-tab">
        <div class="stats-tab-header">
          <u-icon name="analytics" type="fa" />
          Estatísticas
        </div>
        <ul>
          <stats-tab-item label="Vendidos" :count="e.vendidos" :value="pctVendidos" color="positive" />
          <stats-tab-item label="Condicionais" :count="e.condicional" :value="pctCondicional" color="warning" />
          <stats-tab-item label="Não vendidos" :count="e.naoVendidos" :value="pctNaoVendidos" color="negative" />
          <stats-tab-item label="Para leiloar / Saldo" :count="e.paraLeiloar" :value="pctParaLeiloar" color="info" />
          <stats-tab-item label="Total em vendas" count="" :value="totalVendidoValor" color="t text-positive" />
          <stats-tab-item label="Total condicionais" count="" :value="totalVCondicionalValor" color="t text-warning" />
        </ul>
      </div>

      <auxiliar-lance :lote="lote" :leilao="leilao" @lance="(v) => lance(v)" limit="6" />

      <mudar-incremento ref="lanceIncremento" @alterarIncremento="(v) => alterarIncremento(v)" :lote="lote"
                        :leilao="leilao" />

    </div>
  </div>
</template>
