<script>
export default {
  name: 'btn-confirm',
  props: {
    autoConfirm: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      buttonClosed: false,
      opened: false,
      message: null,
      btnConfirm: 'Confirmar',
      btnConfirmColor: 'btn-success',
      btnCancel: 'Cancelar',
      parseObject: null
    }
  },
  computed: {
    getMessage () {
      return this.message
    }
  },
  mounted () {
    document.body.append(this.$el)
  },
  watch: {},
  destroyed () {
  },
  methods: {
    closeButtonConfig () {
      this.buttonClosed = true
    },
    open (message, btnConfirm, btnCancel, parseObject, cb) {
      if (this.autoConfirm) {
        return cb(true, parseObject)
      }
      let btnConfirmMessage = 'Tem certeza?'
      let btnConfirmColor = 'btn-success'
      if (btnConfirm instanceof Object) {
        btnConfirmMessage = btnConfirm.message
        btnConfirmColor = btnConfirm.color
      } else {
        btnConfirmMessage = btnConfirm
      }
      window.disableShortKey = true
      this.opened = true
      this.message = message
      this.btnConfirm = btnConfirmMessage
      this.btnConfirmColor = btnConfirmColor
      this.btnCancel = btnCancel
      this.parseObject = parseObject
      this.cb = cb
      this.$nextTick(() => {
        this.$refs.btn.focus()
      })
    },
    close () {
      window.disableShortKey = false
      this.opened = false
    },
    response (v) {
      console.log('ESC')
      this.cb(v, this.parseObject)
      this.close()
    }
  },
  components: {}
}
</script>

<template>
  <div @keyup.prevent.esc="response(false)">
    <div class="u-bg-overlay" v-if="opened"></div>
    <div class="u-theme-config-container" :class="{'is-closed': opened}">

      <div class="u-theme-config-panel" :class="{'open': opened}">

        <div v-if="opened" class="u-theme-content">

          <div class="message">{{ message }}</div>

          <p class="m-t-xl no-wrap text-nowrap">
            <button ref="btn" tabindex="1" class="u-btn u-btn-no-uppercase bg-positive text-white b-radius-3px m-r-xs"
                    @click="response(true)"
                    :class="btnConfirmColor">{{ btnConfirm }}
            </button>
            <button class="u-btn u-btn-no-uppercase bg-grey-4 text-black b-radius-3px" tabindex="1"
                    @click="response(false)">{{ btnCancel }}
            </button>
          </p>

        </div>
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
  .u-bg-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
    z-index: 9999996;
  }

  .u-theme-config-container {
    position: fixed;
    z-index: 9999997;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 30px;
    display: flex;
    align-content: center;
    align-items: center;

    &.is-closed {
      animation-name: fecharBonitin;
      animation-duration: .2s;
      -webkit-animation-fill-mode: forwards;
    }
  }

  .u-theme-config i:before {
    font-size: 50px;
  }

  .u-theme-config-panel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: fixed;
    z-index: 9999998;
    right: -300px;
    width: 480px;
    max-height: calc(100vh - 200px);
    background: #fff;
    border-radius: 5px 0;
    box-shadow: 0 2px 14px 0 rgba(97, 123, 160, 0.4);
    opacity: 0;
    transition: all 0.3s;
    margin: auto;

    &.open {
      right: 0 !important;
      opacity: 1;
      transition: all 0.3s;
    }
  }

  .u-theme-content {
    padding: 22px;
  }

  .message {
    font-size: 18px;
  }

</style>
