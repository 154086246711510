<template>
  <div class="cronometro-keyboard">
    <u-btn label="Alterar Cronômetro" no-caps @click="digitarTempo" v-shortkey="['shift', 't']"
           @shortkey.native="digitarTempo" class="hide" />

    <!-- DIALOG -->
    <u-dialog
        v-model="digitarTempoDialog"
        @cancel="digitarTempoCancel"
        @ok="digitarTempoConfirm"
    >
      <span slot="title">Alterar Tempo do Cronômetro</span>

      <!--<span slot="message">What is your superhero of choice?</span>-->

      <div slot="body">
        <u-field
            icon="attach_money"
            label="Novo Tempo:"
            :label-width="3"
            helper="Em segundos. Exemplo: 15"
        >
          <u-input ref="defInput" autofocus v-model="digitarTempoValor" @keydown.prevent.enter="digitarTempoConfirm"
                   class="no-shortkey" />
        </u-field>
        <div class="m-t">
          <u-checkbox v-model="alterarTudo" label="Alterar no leilão inteiro" />
        </div>
      </div>

      <template slot="buttons" slot-scope="props">
        <u-btn flat label="Cancelar" @click="props.cancel" />
        <u-btn color="primary" label="Confirmar alteração" @click="props.ok" />
      </template>
    </u-dialog>
  </div>
</template>

<script>
import {UDialog, UField, UInput, UCheckbox} from 'uloc-vue'

export default {
  name: 'AlteraCronometro',
  components: {UDialog, UInput, UField, UCheckbox},
  data () {
    return {
      digitarTempoDialog: false,
      digitarTempoValor: null,
      alterarTudo: false
    }
  },
  methods: {
    digitarTempo () {
      this.digitarTempoDialog = true
      window.setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.defInput.focus()
        })
      }, 400)
    },
    digitarTempoConfirm () {
      this.$emit('alterarCronometro', {
        tudo: this.alterarTudo,
        tempo: Number(String(this.digitarTempoValor).replace(/\D/gi, ''))
      })
      this.resetAlteracaoTempo()
    },
    digitarTempoCancel () {
      this.resetAlteracaoTempo()
    },
    resetAlteracaoTempo () {
      this.digitarTempoDialog = false
      this.digitarTempoValor = null
    }
  }
}
</script>
