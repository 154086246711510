<template>
  <div class="auxiliar-lance">
    <div class="title">AUXILIAR DE LANCE</div>
    <div class="buttons">
      <u-btn @click="$emit('lance', valor)" v-for="valor in valores" :key="valor" :label="'R$ ' + $options.filters.moeda(valor)" />
    </div>
  </div>
</template>

<script>
import {UDialog, UInput, UField} from 'uloc-vue'
export default {
  name: 'AuxiliarLance',
  inject: ['controlador'],
  props: ['leilao', 'lote', 'limit'],
  computed: {
    valores () {
      if (!this.lote.valorIncremento || !this.controlador.valorAtual) {
        return []
      }
      const valorAtual = this.controlador.valorAtual
      const incremento = this.lote.valorIncremento
      let valores = []
      let limit = 9
      if (this.limit){
        limit = this.limit
      }
      let rest = Math.round(limit*(30/100))
      let init = limit-rest
      for (let i = 1; i <= init; i++) {
        valores.push(valorAtual + (incremento*i))
      }
      for (let i = 1; i <= rest; i++) {
        valores.push(valorAtual + ((incremento*10)*i))
      }
      return valores
    }
  }
}
</script>
