<template>
  <div class="mudar-incremento">
    <div class="title">MUDAR INCREMENTO</div>
    <div class="buttons">
      <u-btn class="i-btn" @click="$emit('alterarIncremento', incremento1)">R$ {{incremento1|moeda}}</u-btn>
      <u-btn class="i-btn" @click="$emit('alterarIncremento', incremento2)">R$ {{(incremento2)|moeda}}</u-btn>
    </div>
    <div class="lbtn">
      <u-btn @click="digitarIncremento" label="Digitar incremento" no-caps v-shortkey="['f3']"
             @shortkey.native="digitarIncremento" />
      <span>F3</span></div>

    <!-- DIALOG -->
    <u-dialog
        v-model="digitarIncrementoDialog"
        @cancel="digitarIncrementoCancel"
        @ok="digitarIncrementoConfirm"
    >
      <span slot="title">Alterar Incremento</span>

      <!--<span slot="message">What is your superhero of choice?</span>-->

      <div slot="body">
        <u-field
            icon="attach_money"
            helper="Recomendamos um valor múltiplo de R$ 100"
            label="Novo incremento:"
            :label-width="3"
        >
          <u-input ref="incrementoInput" autofocus v-model="digitarIncrementoValor" @keydown.prevent.enter="digitarIncrementoConfirm" class="no-shortkey" v-money="money" />
        </u-field>
      </div>

      <template slot="buttons" slot-scope="props">
        <u-btn flat label="Cancelar" @click="props.cancel" />
        <u-btn color="primary" label="Confirmar alteração" @click="props.ok" />
      </template>
    </u-dialog>
  </div>
</template>

<script>
import {UDialog, UInput, UField} from 'uloc-vue'
import {convertRealToMoney, REAL_BRL} from '@/utils/money'
import {VMoney} from 'v-money'

export default {
  name: 'MudarIncremento',
  inject: ['controlador'],
  props: ['leilao', 'lote'],
  directives: {money: VMoney},
  components: {UDialog, UInput, UField},
  data () {
    return {
      money: REAL_BRL,
      digitarIncrementoDialog: false,
      digitarIncrementoValor: null
    }
  },
  computed: {
    incremento1 () {
      return this.lote.valorIncremento * 4
    },
    incremento2 () {
      return this.lote.valorIncremento * 10
    }
  },
  methods: {
    digitarIncremento () {
      // this.resetAlteracaoIncremento()
      this.digitarIncrementoDialog = true
      window.setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.incrementoInput.focus()
        })
      }, 400)
    },
    digitarIncrementoConfirm () {
      this.$emit('alterarIncremento', convertRealToMoney(this.digitarIncrementoValor))
      this.resetAlteracaoIncremento()
    },
    digitarIncrementoCancel () {
      this.resetAlteracaoIncremento()
    },
    resetAlteracaoIncremento () {
      this.digitarIncrementoDialog = false
      this.digitarIncrementoValor = null
    }
  }
}
</script>
