<template>
  <div class="lance-minimo-keyboard">
    <u-btn label="Lançar manualmente" no-caps @click="digitarLance" v-shortkey="['ctrl', 'f10']"
                             @shortkey.native="digitarLance" class="hide" />

    <!-- DIALOG -->
    <u-dialog
        v-model="digitarLanceDialog"
        @cancel="digitarLanceCancel"
        @ok="digitarLanceConfirm"
    >
      <span slot="title">Alterar Lance Mínimo</span>

      <!--<span slot="message">What is your superhero of choice?</span>-->

      <div slot="body">
        <u-field
            icon="attach_money"
            label="Lance Mínimo:"
            :label-width="3"
        >
          <u-input ref="defInput" autofocus v-model="digitarLanceValor" @keydown.prevent.enter="digitarLanceConfirm" class="no-shortkey" v-money="money" />
        </u-field>
      </div>

      <template slot="buttons" slot-scope="props">
        <u-btn flat label="Cancelar" @click="props.cancel" />
        <u-btn color="primary" label="Confirmar alteração" @click="props.ok" />
      </template>
    </u-dialog>
  </div>
</template>

<script>
import {UDialog, UField, UInput} from 'uloc-vue'
import {VMoney} from 'v-money'
import {REAL_BRL, convertRealToMoney} from '@/utils/money'

export default {
  name: 'LanceMinimo',
  directives: {money: VMoney},
  components: {UDialog, UInput, UField},
  data () {
    return {
      money: REAL_BRL,
      digitarLanceDialog: false,
      digitarLanceValor: null
    }
  },
  methods: {
    digitarLance () {
      // this.resetAlteracaoLance()
      this.digitarLanceDialog = true
      window.setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.defInput.focus()
        })
      }, 400)
    },
    digitarLanceConfirm () {
      //this.$emit('lanceMinimo', Number(String(this.digitarLanceValor).replace(/\D/gi, '')))
      this.$emit('lanceMinimo', convertRealToMoney(this.digitarLanceValor))
      this.resetAlteracaoLance()
    },
    digitarLanceCancel () {
      this.resetAlteracaoLance()
    },
    resetAlteracaoLance () {
      this.digitarLanceDialog = false
      this.digitarLanceValor = null
    }
  }
}
</script>
