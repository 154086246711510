<template>
  <div class="leilao-status">
    <div class="avisoLanceAutomatico" v-if="mostrarAvisoLanceAutomatico && hasLancesAutomaticos">
      <strong>ATENÇÃO:</strong> LANCE AUTOMÁTICO ATÉ <strong>R$ {{ maiorLanceAutomativo|moeda }}</strong>
    </div>
    <div class="cronometro-flex">
      <div v-if="leilao.cronometro !== false" class="shortcut-time"><strong>T</strong> para renovar o tempo ou <strong>SHIFT+T</strong> para alterá-lo.
        Atual: {{ leilao.timerPregao }} segundos
      </div>
      <div v-if="leilao.cronometro !== false" class="cronometro lance-timer"
           :class="{'lance-danger': controlador.timerPregao <= controlador.calcPercentTimer(30)}">
        {{ controlador.timerPregaoFormatado }}
      </div>
      <div class="leilao-status-lote"><span :style="'color: ' + colorStatus">{{ controlador.loteStatusString }}</span>
      </div>
      <div v-if="!controlador.ultimoLance">
        <div class="inicial-title">Lance Inicial</div>
        <div class="inicial-val">R$ {{ controlador.valorInicial | moeda }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Status from '../../../../../domain/leiloes/helpers/LoteStatus'

export default {
  name: 'Status',
  inject: ['controlador'],
  props: {
    lote: {required: false},
    leilao: {required: false},
    mostrarAvisoLanceAutomatico: {type: Boolean, required: false, default: false}
  },
  computed: {
    colorStatus() { // TODO
      if (this.lote.status === null) {
        return '#FFFFFF'
      }
      if (typeof Status.StatusFake[this.lote.status]['color'] !== 'undefined') {
        return Status.StatusFake[this.lote.status]['color']
      }
      return '#FFFFFF'
    },
    hasLancesAutomaticos() {
      return this.lote.lancesAutomaticos && Array.isArray(this.lote.lancesAutomaticos) && this.lote.lancesAutomaticos.length
    },
    maiorLanceAutomativo () {
      const result = this.lote.lancesAutomaticos.slice().filter(l => l.active).sort((a, b) => {
        return (a.valorLimite === b.valorLimite ? 0 : a.valorLimite > b.valorLimite ? -1 : 1) * 1
      })
      return result[0].valorLimite
    }
  }
}
</script>

<style>

</style>
