<template>
  <div class="c-stats1">
    <div>
      Leilão {{leilao.codigo}}
    </div>
    <div class="lblc lances-automaticos existe_"
         title="Se algum arrematante configurou lances automáticos para este lote, o valor limite é informado aqui.">
      <span class="cslbl">Lances automáticos</span>
      <span class="value">Nenhum</span>
    </div>
    <div class="lblc status-leilao" :class="[leilao.pregaoAtivo ? 'aberto' : 'fechado']">
      <!-- class `fechado` p/ leilão fechado -->
      <div v-if="leilao.pregaoAtivo">
        <e-btn-circle-status status="positive" class="m-r-xs" />
        <span class="value">Leilão Aberto</span></div>
      <div @click="abrirLeilao" class="cursor-pointer" v-else>
        <e-btn-circle-status v-shortkey="['f4']" @shortkey.native="abrirLeilao" status="negative" class="m-r-xs" />
        <span class="value">Leilão Fechado (F4 p/ abrir)</span></div>
    </div>
    <div class="lblc">
      <span class="cslbl">Lotes</span>
      <span class="value">{{leilao.totalLotes}}</span>
    </div>
    <div class="lblc">
      <span class="cslbl">Lote Anterior</span>
      <span class="value">{{loteAnterior}}</span>
    </div>
    <div class="lblc">
      <span class="cslbl">Próximo Lote</span>
      <span class="value text-blue">{{loteProximo}}</span>
    </div>
    <div class="lblc">
      <span class="cslbl">Faltam</span>
      <span class="value text-negative">{{loteRestante}}</span>
    </div>

    <btn-menu-sup
        @fecharLeilao="fecharLeilao"
        class="menu-sup-right" :leilao="leilao" />
  </div>
</template>

<script>
import {EBtnCircleStatus} from 'uloc-vue-plugin-erp'
import BtnMenuSup from './BtnMenuSup'

export default {
  name: 'StatsSup',
  props: ['leilao'],
  inject: ['controlador'],
  components: {BtnMenuSup, EBtnCircleStatus},
  computed: {
    loteAnterior () {
      if (!this.controlador.loteAnterior) {
        return '-'
      }
      return this.controlador.loteAnterior.numero
    },
    loteProximo () {
      if (!this.leilao.pregaoAtivo || !this.leilao.pregaoAtivo.loteProximo) {
        return '-'
      }
      return this.leilao.pregaoAtivo.loteProximo
    },
    loteRestante () {
      if (!this.leilao.pregaoAtivo || !this.leilao.pregaoAtivo.loteRestante) {
        return '-'
      }
      return this.leilao.pregaoAtivo.loteRestante
    }
  },
  methods: {
    abrirLeilao () {
      this.$uloc.dialog({
        title: 'Você está prestes a abrir o auditório virtual',
        message: 'Você tem certeza que deseja abrir o leilão para o público?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'positive'
      })
        .then(() => {
          console.log('Abrindo leilão...')
          this.comunicatorClass.abrirLeilao(this.leilao.id)
            .then(({data}) => {
              console.log(data)
            })
            .catch(error => {
              console.log(error)
              this.alertApiError(error)
            })
        })
        .catch(() => {

        })
    },
    fecharLeilao () {
      this.$uloc.dialog({
        title: 'Você está prestes a fechar o auditório virtual',
        message: 'Você tem certeza que deseja fechar o leilão para o público?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      })
        .then(() => {
          console.log('Fechando leilão...')
          this.comunicatorClass.fecharLeilao(this.leilao.id)
            .then(({data}) => {
              console.log(data)
            })
            .catch(error => {
              console.log(error)
              this.alertApiError(error)
            })
        })
        .catch(() => {

        })
    }
  }
}
</script>
